.searchSuggestion{
    display: flex;
    padding: 5px ;
}
.searchSuggestion:hover{
    background-color: #ADD8E6;
    color: white;
}

.searchDiv{
    position: absolute; 
    top: 59px;
    left: 35px;
    display: block; 
    min-width: 500px; 
    padding: 12px 16px; 
    z-index: 1 ;
    background-color: white; 
    border-radius: 20px;
}
/* .loading-div{
    display:flex; 
    width: 100%;
    justify-content:center; 
    align-items:center;
}
.publisher-div{
    display: flex; 
    width: 100%;
    justify-content: center; 
    align-items: center;
}
.course-title{
    font-size: 20px; 
    padding-left: 6px; 
    cursor: pointer;
} */