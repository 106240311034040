.buyButton p {
  font-size: 13px;
}
.profileContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profileContainer p {
  font-size: 13px;
}
.profileContainer img {
  width: 25px;
  border-radius: 50%;
}

.cardContainer {
  padding: 5px;
}

.thumbImageCard {
  width: 100%;
  height: 170px;
}

/*   fontSize: "10px",
                  marginRight: "30px",
                  color: "black",
                  fontWeight: "400", */

/*  fontSize: "10px",
                width: "150px",
                backgroundColor: "#6d31ed",
                color: "white", */
