.menuLink {
    cursor: pointer;
}


.profileMenuLinks {
    display: flex;
    gap:5px;
    align-items: center;
}


.headerIconLinks {
    cursor: pointer;
}


