.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  display: inline-block;
  margin-right: 5px;
}

.pagination li a {
  color: #000;
  text-decoration: none;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.pagination a.active {
  background-color: #6d31ed;
  color: #fff;
}

.pagination li.active a {
  background-color: #6d31ed;
  color: #fff;
}

.pagination li a:hover {
  background-color: #6d31ed;
  color: #fff;
}
