.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin: 3px 0px;
}

.overlay-panel h1 {
  font-weight: bold;
  margin: 0;
}

.overlay-panel h2 {
  text-align: center;
}

.overlay-panel p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.overlay-panel span {
  font-size: 12px;
}

.overlay-panel a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.overlay-panel button {
  border-radius: 20px;
  border: 1px solid #6a65d5;
  background-color: #6a65d5;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.overlay-panel button:active {
  transform: scale(0.95);
}

.overlay-panel button:focus {
  outline: none;
}

.overlay-panel button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.overlay-panel form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5px;
  height: 100%;
  text-align: center;
}

.overlay-panel input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  border-radius: 10px;
}

/* signin css */

.sign-in-container h1 {
  font-weight: bold;
  margin: 0;
}

.sign-in-container h2 {
  text-align: center;
}

.sign-in-container p {
  color: black;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.sign-in-container span {
  font-size: 12px;
  color: black;
}

.sign-in-container a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.sign-in-container button {
  border-radius: 20px;
  border: 1px solid #6a65d5;
  background-color: #6a65d5;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.sign-in-container button:active {
  transform: scale(0.95);
}

.sign-in-container button:focus {
  outline: none;
}

.sign-in-container button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.sign-in-container form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5px;
  height: 100%;
  text-align: center;
}

.sign-in-container input {
  color: black;
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  border-radius: 10px;
}

/* signup css */

.sign-up-container h1 {
  font-weight: bold;
  margin: 0;
}

.sign-up-container h2 {
  text-align: center;
}

.sign-up-container p {
  color: black;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.sign-up-container span {
  font-size: 12px;
  color: black;
}

.sign-up-container a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.sign-up-container button {
  border-radius: 20px;
  border: 1px solid #6a65d5;
  background-color: #6a65d5;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  margin: auto;
}

.sign-up-container button:active {
  transform: scale(0.95);
}

.sign-up-container button:focus {
  outline: none;
}

.sign-up-container button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.sign-up-container form {
  background-color: #ffffff;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  /* flex-direction: column; */
  padding: 10px 5px;
  height: 100%;
  text-align: center;
  /* overflow: auto; */
}

.sign-up-container input {
  background-color: #eee;
  color: black;
  border: none;
  padding: 8px 15px;
  margin: 6px 0;
  width: 100%;
  border-radius: 10px;
}

.logincontainer {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 850px;
  max-width: 100%;
  min-height: 560px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.logincontainer.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.logincontainer.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  /* animation: show 0.6s; */
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  /* overflow: hidden; */
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.logincontainer.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #656bc9;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.logincontainer.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.logincontainer.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.logincontainer.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 4px 0;
  cursor: pointer;
}

.social-container a {
  border: 1px solid #dddddd;
  /* border-radius: 50%; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

#acontpara {
  margin: 0 0 20px !important;
}

::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 6px; /* Rounded corners on the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

.radio-label {
  display: flex;
  align-items: baseline;
  padding-left: 13px;
}
.user-type {
  display: flex;
  width: 100%;
  justify-content: center;
}
.error-message {
  margin: 0 !important;
  color: red !important;
}

.account-type-radio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.account-type-radio label {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  padding-right: 20px;
  font-size: 16px;
  color: black;
}

.account-type-radio input[type="radio"] {
  color: black;
  margin-right: 8px;
  cursor: pointer;
}


.errorFieldLogin {
  position: absolute;
  font-size: 12px!important;
  color: red!important;
  top:36px;
  font-style: italic;
}

.errorFieldSignup{
  position: absolute;
  font-size: 12px!important;
  color: red!important;
  top:26px;
  font-style: italic;
}