/* #app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  } */

/* h2 {
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
} */

form {
  /* background-color: #e47474; */
  padding: 20px;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  margin-left: 10px;
}

form div {
  /* margin-bottom: 15px; */
  text-align: center;
}

label {
  font-weight: bold;
  text-align: center;
}

/* input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 40%;
    padding: 10px;
    border: 1px solid #007bff;
    border-radius: 15px;
    text-align: center;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
  } */

button[type="submit"] {
  background-color: #007bff;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  text-align: center;
}

button[type="submit"]:hover {
  background-color: #0056b3;
  color: #f3f3fb;
}

.swiper {
  width: 100%;
  height: 100%;
  padding: 39px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff;
   */
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-prev,
.swiper-button-next {
  background-color: var(--main-blue);
  color: var(--white-color);
  border-radius: 100%;
  width: 32px;
  height: 32px;
  margin-top: -11px;
}

/* .accord-style .accordion {
  color: var(--main-black);
  font-size: 15px;
  font-weight: 700;
} */
.cardpad {
  padding: 1%;
}
.bullet ul li {
  list-style: disc;

  margin-left: 35px;
}
.accordion .accordion-item .accordion-button {
  font-size: 15px;
}
.card-header {
  background-color: transparent;
}
.accordion .accordion-item .accordion-button {
  background-color: #f3f3fb;
}
.center {
  justify-content: center;
  position: center;
  right: 0;
  top: 0;
}
/* .accord{
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%23435971' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' fill='%23566a7f' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m1.532 12 6.182-6-6.182-6L0 1.487 4.65 6 0 10.513z'/%3E%3C/defs%3E%3Cg transform='translate%282.571%29' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%23435971' xlink:href='%23a'/%3E%3Cuse fill-opacity='.1' fill='%23566a7f' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E%0A");
} */
/* .accordion .accordion-button::after{
  filter:none;
} */
