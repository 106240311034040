.popupContainer {
    position: fixed;
    top: 70%;
    z-index: 3;
    right: 75px;
    background: rgb(255 255 255);
    padding: 10px;
    border-radius: 10px;
    width: 24%;
    transform: translate(50px, 350px);    /* display: none; */
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.popupContainer.ContainerActive {
    transform: translate(50px, 100px);
    transition: all 0.3s ease-in-out;
    opacity: 1;


}

.popupContent {}

.popupHeader {
    margin: 10px 20px;
    font-size: 16px;
    font-weight: 500;
}

.popupFooter {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 10px 18px;
}

.updateButton {
    color: #ffff;
}