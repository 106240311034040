.profileImage img {
    width: 25px;
    border-radius: 50%;

}

.profileImage {
    display: flex;
    gap: 10px;
}

.reviewStars {
    display: flex;
}

.reviewContainer {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.reviewDetails p {
    margin-top: 10px;
    text-wrap: pretty;
    overflow: hidden;
}

.reviewDetails {
    width: 100%;
    border-bottom: 0.5px solid #cfcfcf;
}

.addReview i,
.reviewStars i {
    color: #6534e4
}

.giveRatings {
    /* width: 930px; */
    /* display: flex; */
    margin-top: 10px;
    /* justify-content: center; */
}

.giveRatings i {
    font-size: 25px;
    cursor: pointer;
}

.inputContainer {
    /* display: flex; */
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.inputContainer button {
    margin-top: 10px;
}

.reviewedList {
    overflow: overlay;
    height: 300px;
    padding: 4px;
    border: 1px solid #d1d0d0;
    border-radius: 5px;
    /* width: 930px; */
}


.leftContainerDiv {
    max-width: 100%;
    width: 58%;
}

.widthFull {
    width: 100%;
}

.reviewStarsComment {
    margin-left: 35px;
    margin-top: 2px;
}

.reviewStarsComment p {
    margin-bottom: 5px;
}