::-webkit-scrollbar {
    width: 0px;
  }

.main-container-JobSearchResult{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
}

.leftside-JobSearchResult{
    width: 22%;
    height: auto;
    overflow-y: scroll;
}
.leftside-div1{
    width: 100%;
    height: 200px;
    margin-top: 30px;
    border-radius: 8px; /* border-xl */
    border-width: 1px; 
    border-color: #F3F4F6FF; /* neutral-200 */
    border-style: solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.active{
    background: #F5F1FEFF; /* primary-100 */
}

.leftside-div1-mainlogodiv{
    width: 90%;
    height: 40px;
    display: flex;
    justify-content: space-between;

}
.leftside-div1-logo{
    width: 20%;
    height: 100%;
}
.leftside-div1-jobtitle{
    width: 75%;
    height: 100%;
}
.leftside-div1-jobtitle-h1{
    font-family: Manrope;
    font-size: 18px; 
    font-weight: bold; 
}
.leftside-div1-jobtitle-h2{
    font-family: Manrope; /* Body */
    font-size: 14px; 
    font-weight: 400;
    color: #171A1FFF;
}
.leftside-div1-small-logo-div{
    width: 90%;
    height: 15px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.leftside-div1-small-logo{
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.leftside-div1-location-div{
    width: 87%;
    height: 100%;
}
.leftside-div1-jobtitle-h3{
    font-family: Manrope; /* Body */
    font-size: 14px; 
    color: #9095A1FF;
    font-weight: 400;
}

.rightside-JobSearchResult{
    width: 72%;
    height: auto;
}
.rightside-div1{
    width: 100%;
    height: auto;
    margin-top: 30px;
    background-color: black;
    background: #FFFFFFFF; 
    border-radius: 4px; /* border-m */
    box-shadow: 0px 2px 2px #1f212510, 0px 0px 2px #1d212757;
    display: flex;
    justify-content: space-between;
}
.rightside-div1-leftdiv{
    width: 35%;
    height: auto;
}

.rightside-div1-leftdiv-h1{
    font-family: Lexend; /* Heading */
    font-size: 20px; 
    font-weight: 700; 
    color: #171A1FFF;
    padding-top: 8px;
    padding-left: 8px;
}
.rightside-div1-leftdiv-h2{
    font-family: Manrope; /* Body */
    font-size: 12px; 
    line-height: 28px; 
    font-weight: 700; 
    color: #6D31EDFF;
    padding-top: 5px;
    padding-left: 8px;
}
.rightside-div1-leftdiv-icondiv{
    display: flex;
    width: 60%;
    height: auto;
    justify-content: space-between;
    padding-top: 5px;
    padding-left: 8px;
}
.rightside-div1-leftdiv-buttondiv{
    width: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-left: 8px;
    margin-bottom: 10px;

}
.rightside-div1-leftdiv-button{
    padding: 2px 8px; 
    font-family: Manrope; 
    font-size: 12px; 
    line-height: 22px; 
    font-weight: 400; 
    color: #9095A1FF; /* neutral-500 */
    background: #F3F4F6FF; 
    opacity: 1; 
    border-radius: 4px; /* border-m */
    border-width: 1px; 
    border-color: #9095A1FF; /* neutral-500 */
    border-style: solid;
    margin-left: 2px;
}
.rightside-div1-rightdiv{
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.rightside-div1-rightdiv-buttondiv{
    width: 100%;
    height: auto;
    padding-top: 12px;
    display: flex;
    justify-content: center;
}
.rightside-div1-rightdiv-button1{
    font-family: Manrope; 
    font-size: 20px; 
    font-weight: 400; 
    color: #986EF2FF; 
    opacity: 1; 
    border: none; 
    border-radius: 4px;
    padding: 0 12px;
}
.rightside-div1-rightdiv-button2{
    font-family: Manrope; 
    font-size: 16px; 
    font-weight: 400; 
    color: #986EF2FF; 
    opacity: 1; 
    border: none; 
    border-radius: 4px;
    padding: 0 12px;
    background: #F5F1FEFF; 
    padding: 1px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;

}
.rightside-div1-rightdiv-button3{
    font-family: Manrope; 
    opacity: 1; 
    border-radius: 4px;
    background: #F5F1FEFF; 
    padding: 3px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px; 
    font-weight: 400; 
    color: #DACBFAFF; 
    background: #6D31EDFF; 
    border: none; 
    border-radius: 4px;

}
.rightside-div1-JobSearchResult-h1{
    font-family: Manrope; 
    font-size: 14px; 
    color: #9095A1FF;
    font-weight: 400;
    text-align: right;
    padding-right: 40px;
    padding-top:10px;
}
.rightside-div2-JobSearchResult{
    width: 100%;
    height: auto;
    margin-top: 30px;
    background: #FFFFFFFF; 
    border-radius: 4px; /* border-m */
    box-shadow: 0px 2px 2px #1f212510, 0px 0px 2px #1d212757;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}
.rightside-div2-JobSearchResult-leftdiv{
    width: 30%;
    height: auto;
}
.rightside-div2-JobSearchResult-h1{
    font-family: Manrope; /* Body */
    font-size: 16px; 
    font-weight: 700; 
    color: #171A1FFF;
    padding: 15px;
}
.rightside-div2-JobSearchResult-photoandtextdiv{
    width: auto;
    height: auto;
    margin-left: 15px;
    margin-top: 5px;
    display: flex;
    align-items: center;
}
.rightside-div2-JobSearchResult-photodiv{
    width: 32px;
    height: 28px;
    background: #B9E5FFFF; 
    opacity: 1; 
    overflow: hidden; 
    border-radius: 22px;
}
.rightside-div2-JobSearchResult-textdiv{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.rightside-div2-JobSearchResult-text1{
    font-family: Manrope; /* Body */
    font-size: 14px; 
    font-weight: 700; 
    color: #171A1FFF;
    padding-left: 15px;
    display: block;

}
.rightside-div2-JobSearchResult-text2{
    font-family: Manrope; /* Body */
    font-size: 12px; 
    font-weight: 400; 
    opacity: 1;
    padding-left: 2px; 
    padding-right: 2px;
    color: rgb(79, 124, 151);
    background-color: #F5F1FEFF;
    padding: 2px 3px;
    border-radius: 10px;
}
.rightside-div2-JobSearchResult-text3{
    font-family: Manrope; 
    font-size: 14px; 
    font-weight: 400; 
    color: #6F7787FF;
    padding-left: 15px;
}
.rightside-div2-JobSearchResult-rightdiv{
    width: 15%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightside-div2-JobSearchResult-text{
    font-family: Manrope; 
    font-size: 12px; 
    font-weight: 400; 
    color: #6D31EDFF; /* primary-500 */
    background: #00000000; /* transparent */
    opacity: 1; 
    border: none; 
    border-radius: 4px;
    padding-left: 4px;
}
.rightside-div3{
    width: 100%;
    height: auto;
    margin-top: 30px;
    background: #FFFFFFFF; 
    border-radius: 4px; /* border-m */
    box-shadow: 0px 2px 2px #1f212510, 0px 0px 2px #1d212757;
}
.rightside-div3-h1{
    font-family: Manrope; /* Body */
    font-size: 14px; 
    font-weight: 400; 
    color: #171A1FFF;
    padding: 8px;
}
.rightside-div3-h2{
    font-family: Manrope; /* Body */
    font-size: 12px; 
    font-weight: 700; 
    color: #323743FF;
    padding-left: 8px;
    padding-bottom: 8px;
}
.rightside-div4{
    width: 100%;
    height: auto;
    padding-bottom: 25px;
    margin-top: 30px;
    border-radius: 4px; /* border-m */
}
.rightside-div4-div1{
    width: 95%;
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
}


.rightside-div4-div1-h1{
    font-family: Manrope; /* Body */
    font-size: 14px; 
    line-height: 26px; 
    font-weight: 700; 
    color: #171A1FFF;
    padding-left: 15px;
}
.rightside-div4-div1-button{
    font-family: Manrope; 
    font-size: 11px; 
    line-height: 22px; 
    font-weight: 400; 
    color: #6D31EDFF; /* primary-500 */
    background: #00000000; /* transparent */
    opacity: 1; 
    border: none; 
    border-radius: 4px;
    margin-left: 15px;
}
.rightside-div4-div1-h2{
    font-family: Lexend; /* Heading */
    font-size: 16px; 
    font-weight: 700; 
    color: #171A1FFF;
    padding-top: 15px;
    padding-left: 15px;
}
.rightside-div4-div1-h3{
    font-family: Manrope; /* Body */
    font-size: 14px; 
    font-weight: 400; 
    color: #171A1FFF;
    padding-top:15px;
    padding-left: 15px;
    padding-right: 25px;
}
.rightside-div4-logodiv{
    width: 50%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

} 
.rightside-div4-logodiv-leftdiv{
    width: 35%;
    height: 20px;
    margin-left: 15px;
    font-family: Manrope; /* Body */
    font-size: 14px; 
    line-height: 26px; 
    font-weight: 400; 
    color: #6F7787FF;
    display:flex;
    align-items: center;
}
.rightside-div4-logodiv-rightdiv{
    width: 35%;
    height: 20px;
    margin-left: 15px;
    font-family: Manrope; /* Body */
    font-size: 14px; 
    line-height: 26px; 
    font-weight: 400; 
    color: #6F7787FF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
/* .rightside-div4-div1-h1{
    font-family: Manrope;
    font-size: 10px; 
    line-height: 26px; 
    font-weight: 400; 
    color: #6F7787FF;
    padding-left: 15px;
}  */



