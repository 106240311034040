.parentSubSectionList {
    margin: 5px 0;
    padding: 8px;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    border-bottom: 1px solid #929292;
}

.parentSubSectionList:hover {
    background: rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease-in-out;

}

.activeCourseSection {
    background: rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease-in-out;

}

.selectedVideoActive {
    background: #cecece;
    box-shadow: 1px 1px 11px 1px;
    border-radius: 5px;
}

.listOfVideos {}

.loadingVideos {
    display: flex;
    gap: 10px;
}

.relatedVideosParent {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.relatedCardClass {
    width: 18%;
}

.listOfLinks {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    width: 50%;
}



.parentListLinks {
    position: relative;
    border-radius: 50%;
}

.discountVideos {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 50px;
    justify-content: end;
}
.discountCardClass {
    width: 30%;
}

.externalResourceListContainer {
    position: absolute;
    left: -130px;
    width: 215px;
    top: 29px;
    background: #fff;
    z-index: 0;
    max-height: 143px;
    overflow: overlay;

    border-radius: 5px;
    height: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

}

.downloadContainer:hover .externalResourceListContainer {
    opacity: 1;
    height: auto;
    z-index: 2;
}


.deleteLink {
    font-size: 15px;
    font-weight: 500;
}

.externalList {
    border: 1px solid #333;
    overflow: overlay;
    max-height: 300px;
}

.showList {
    transition: all 0.3s ease-in-out;
    display: inline;
}

.externalListLinks {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    cursor: pointer;
}

.videPlayerContainer {
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px;
    border: 1px solid #929292;
}

.videPlayerContainer video {
    border-radius: 10px;
}

.subListContainer {}


.leftContainerDiv {
    max-width: 75%;
    width: 65%;
}

.editLink {
    position: absolute;
    font-size: 10px;
    top: 0px;
    display: none;
    left: 4px;
    background: #aeabe0;
    padding: 4px;
    border-radius: 50%;
}

.linksContainer:hover .editLink {
    display: inline;
}

.externalFilesText {
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.rightContainer {
    width: 32%;
    position: fixed;
    right: 0;
    padding: 8px;
    background: #ffffff;
    top: 70px;
    height: 100%;
    /* overflow: overlay; */
    border-radius: 3px;
}

.sectionContainer {
    padding: 0px;
    overflow: overlay;
    height: 80vh;
    max-height: 80vh;
}

/* .resourcesBox {
    opacity: 0;
    height: 0;

} */


.parentSubSectionList {
    height: 50px;
    /* overflow: hidden;  */
    transition: height 0.3s;
}

.parentSubSectionList:hover {
    height: 100px;
}

.resourcesBox {
    height: 100%;
    transition: height 0.3s;
    /* opacity: 0; */
    visibility: hidden;
    transition: all 0.3s ease-in-out;

}

.parentSubSectionList:hover .resourcesBox {
    /* opacity: 1; */
    visibility: visible;
    transition: all 0.3s ease-in-out;
}