@font-face {
    font-family: 'lexend'; 
    src: url('../font/Lexend-Bold.ttf');
}

.main_div_JobAndPlacementPage1{
    width: 100%;
    height: auto;
}
/* **************div1 Code************************* */
.div1_JobAndPlacementPage1{
    background-color: #F5F1FEFF;
    width: 100%;
    height: 390px;
    display: flex;
    justify-content: space-between;
}

.div1_leftside_JobAndPlacementPage1{
    width: 30%;
    height: 100%;
}

.div1_leftside_name{
    font-size: 20px; 
    font-weight: bold; 
    color: #171A1FFF;
    padding-top: 15px;
    padding-left: 92px;
    font-family: lexend;
}

.div1_leftside_enternamediv{
    width: 70%;
    height: 38px;
    margin-top:6px;
    margin-left: 92px;
    display: flex;
    align-items: center;
}

.div1_leftside_img1{
    width: 35px;
    height: 35px;
    border-radius: 23px;
}

.div1_leftside_input1{
    background-color: #FFFFFFFF;
    width: 200px;
    height: 35px;
    opacity: 1;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    border-radius: 4px; /* border-m */
    border-width: 1px; 
    border-color: #9095A1FF; /* neutral-500 */
    outline: none
}

.div1_leftside_JobAndPlacementPage1_h1{
    font-weight: bolder;
    font-size: 30px;
    color: #323743FF;
    padding-left: 120px;
    padding-top: 10px;
    font-family: lexend;
}
.div1_leftside_JobAndPlacementPage1_h2{
    font-size: 12px; 
    font-weight: 400; 
    color: #565D6DFF;
    padding-left: 123px;
    padding-top: 10px;
}

.div1-leftside_JobAndPlacementPage1_dropCVdiv{
    width: 320px; 
    height: 160px; 
    background: #FFFFFFFF; /* white */
    border-radius: 6px; /* border-l */
    box-shadow: 0px 4px 5px #1f212510, 0px 0px 1px #1d212757;
    margin-left: 123px;
    margin-top: 10px;
    padding: 20px;
}

.JobAndPlacementPage1_dropCVdiv_div1{
    width: 100%;
    height: 30px;
}

.JobAndPlacementPage1_dropCVheading{
    font-size: 18px; 
    font-weight: bold; 
    color: #171A1FFF;
    font-family: lexend;
}

.JobAndPlacementPage1_CVselectingarea{
    width: 100%;
    height: 50px;
    margin-top: 10px;
    background: #FAFAFBFF; /* neutral-100 */
    border-radius: 6px; /* border-l */
    border-width: 2px; 
    border-color: #BDC1CAFF; /* neutral-400 */
    border-style: dashed;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
}

.JobAndPlacementPage1_buttonDiv{
    width: 100%;
    height: 30px;
    margin-top: 10px;
}

.JobAndPlacementPage1_clearBtn{
    width: 80px; 
    height: 30px; 
    padding: 0 12px;
    font-size: 14px; 
    line-height: 22px; 
    font-weight: 400; 
    color: #9095A1FF; 
    background: #00000000; 
    opacity: 1; 
    border: none; 
    border-radius: 4px;
    cursor: pointer;
}

.JobAndPlacementPage1_uploadBtn{
    width: 80px; 
    height: 30px; 
    padding: 0 12px;
    font-size: 14px; 
    line-height: 22px; 
    font-weight: 400; 
    color:  #FFFFFFFF; 
    background: #6D31EDFF; 
    opacity: 1; 
    border: none; 
    border-radius: 4px;
    cursor: pointer;
}

.div1_middleDiv_JobAndPlacementPage1{
    width: 20%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

.div1_rightside_JobAndPlacementPage1{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 40px;
}
.div1_rightside_h1{
    color: #171A1FFF;
    font-size: 20px;
    font-weight: bolder;
    font-family: lexend;

}
.div1_rightside_h2{
    color: #6D31EDFF;
    font-size: 45px;
    font-weight: 700;
    padding-top: 10px;
    font-family: lexend;
}

.div1_rightside_getOfferBtn{
    width: 110px;
    height: 35px;
    background-color:  #6D31EDFF;
    color:#FFFFFFFF;
    cursor:pointer;
    font-size: 13px;
    margin-left: 50px;
    margin-top: 20px;
    border-radius: 4px;
}


/* *********************Some Extra CSS for Similar Page 'JobAndPlacementPage2.js********************** */
.div1_leftside_JobAndPlacementPage2{
    width: 40%;
    height: 100%;
}

.div1_rightside_JobAndPlacementPage2{
    width: 60%;
    height: 100%;
    /* background-color: red; */
}
.div1_rightside_JobAndPlacementPage2_div1{
    width: 100%;
    height: 35%;
    padding: 15px 10px;
    display: flex;
}
 .div1_rightside_JobAndPlacementPage2_div2{
    width: 80%;
    height: 30%;
    padding-top: 5px;
    padding-bottom: 10px;
    display: flex;
    /* padding-left: 50px; */
    align-items: center;
    float: right;
    flex-wrap: wrap;
}
.div1_rightside_JobAndPlacementPage2_div3{
    width: 100%;
    height: 35%;
    padding: 15px 10px;
    display: flex;
    /* background-color: blue; */
}

.div1_rightside_JobAndPlacementPage2_card1div{
    width: 30%;
    height: 100%;
    background: #FFFFFFFF; /* white */
    border-radius: 8px; /* border-xl */
    box-shadow: 0px 4px 50px #1f212510, 0px 0px 1px #1d212757;
    display: flex;
    justify-content: center;
    align-items: center;
}
.div1_rightside_JobAndPlacementPage2_card2div{
    width: 30%;
    height: 100%;
    background: #FFFFFFFF; /* white */
    border-radius: 8px; /* border-xl */
    box-shadow: 0px 4px 50px #1f212510, 0px 0px 1px #1d212757;    margin: 0px 10px;
    margin: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div1_rightside_JobAndPlacementPage2_card3div{
    width: 40%;
    height: 100%;
}

.div1_rightside_JobAndPlacementPage2_card4div{
    width: 35%;
    height: 100%;
    background: #FFFFFFFF; /* white */
    border-radius: 8px; /* border-xl */
    box-shadow: 0px 4px 50px #1f212510, 0px 0px 1px #1d212757;    margin: 0px 10px;
    margin: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.div1_rightside_JobAndPlacementPage2_card5div{
    width: 35%;
    height: 100%;
    background: #FFFFFFFF; /* white */
    border-radius: 8px; /* border-xl */
    box-shadow: 0px 4px 50px #1f212510, 0px 0px 1px #1d212757;    margin: 0px 10px;
    margin: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.div1_rightside_JobAndPlacementPage2_card6div{
    width: 30px;
    height: 30px;
    margin: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  #15ABFFFF;
    border-radius: 26px;
}
.div1_rightside_JobAndPlacementPage2_card9div{
    width: 200px;
    height: 100%;
    background: #FFFFFFFF; /* white */
    border-radius: 8px; /* border-xl */
    box-shadow: 0px 4px 50px #1f212510, 0px 0px 1px #1d212757;    margin: 0px 10px;
    margin: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div1_rightside_JobAndPlacementPage2_h1{
    color: #171A1FFF;
    font-size:20px;
    font-weight: bolder;
    font-family: lexend;
}
.div1_rightside_JobAndPlacementPage2_h2{
    color: #6D31EDFF;
    font-size: 30px;
    font-weight: 700;
    padding-top: 10px;
    font-family: lexend;
}





/* **************************************************************************************************** */


.div2_JobAndPlacementPage1{
    background-color: white;
    width: 100%;
    height: auto;
    display: flex;
}

.div2_leftside_JobAndPlacementPage1{
    width: 65%;
    height: 100%;
}

.div2_leftside_JobAndPlacementPage1_professionalSkillDiv{
    width: 100%;
    height: auto;
    display: flex;

}

.div2_leftside_JobAndPlacementPage1_professionalSkillDiv1{
    width: 50%;
    height: auto;
}

.div2_leftside_JobAndPlacementPage1_h1{
    font-size: 20px; 
    font-family: lexend;
    color: #6D31EDFF;
    padding-top: 10px;
    padding-left: 45px;
}
.div2_leftside_JobAndPlacementPage1_h2{
    font-size: 14px; 
    font-family: lexend;
    color: #171A1FFF;
    padding-top: 10px;
    padding-left: 45px;
}
.div2_leftside_JobAndPlacementPage1_skilldiv{
    width:100%;
    height: auto;
    margin-top: 10px;
    margin-left: 45px;
    display: flex;
    flex-wrap: wrap;
}

.div2_leftside_JobAndPlacementPage1_skill{
    width: auto; 
    height: 25px; 
    margin: 2px;
    font-size: 10px; 
    line-height: 22px; 
    font-weight: 400; 
    color:  #FFFFFFFF; 
    background: #6D31EDFF; 
    opacity: 1; 
    border: none; 
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;    
    padding:2px;
}
.div2_leftside_JobAndPlacementPage1_AddBtn{
    width: 60px; 
    height: 25px; 
    margin: px;
    font-size: 13px; 
    line-height: 22px; 
    font-weight: 400; 
    color:  #6D31EDFF; 
    background: white; 
    opacity: 1; 
    border: none; 
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
}
.div2_leftside_JobAndPlacementPage1_professionalSkillDiv2{
    width: 12%;
    height: auto;   
}

.div2_leftside_JobAndPlacementPage1_professionalSkillDiv3{
    width: 38%;
    height: 100;
    background-color: red;
    margin-top: 24px;
    background-color: #FFFFFFFF;
    border-radius: 8px;
    box-shadow: 0px 4px 7px #1f212510, 0px 0px 4px #1d212757;
    display: flex;
    align-items: center;
    justify-content: center;
}
.div2_leftside_JobAndPlacementPage1_descdiv{
    width: 100%;
    height: auto;
}
.div2_leftside_JobAndPlacementPage1_desctextarea{
    width: 95%;
    height: 200px;
    background-color: #F3F4F6FF;
    margin-left: 45px;
    margin-top: 5px;
    border-radius: 4px;
    outline: none;
    border: none;
    padding: 10px;
}

.div2_rightside_JobAndPlacementPage1{
    width: 35%;
    height: 100;
}

.div1_rightside_checkEligibilityBtn{
    padding: 10px 20px;
    background-color:  #6D31EDFF;
    cursor:pointer;
    font-size: 13px;
    margin-left: 20%;
    margin-top: 20px;
    margin-bottom: 30px;
    border-radius: 4px;
    font-weight: 400; 
    color: #FFFFFFFF; /* white */
    border: none; 
    float: right;
}

.div2_rightside_JobAndPlacementPage1_div1{
    width: 75%;
    height: 50%;
    background: #FFFFFFFF; 
    border-radius: 6px; 
    box-shadow: 0px 4px 7px #1f212510, 0px 0px 4px #1d212757;
    margin-top: 13px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.div2_rightside_JobAndPlacementPage1_h1{
    font-size: 14px; 
    font-family: lexend;
    color: #171A1FFF;
    text-align:center;
}
.div2_rightside_JobAndPlacementPage1_h2{
    font-size: 9px; 
    font-weight: 700; 
    color: #323743FF;
    margin-top: 5px;
}
.div1_rightside_viewFeedbackBtn{
    padding: 7px 15px;
    background-color:  #6D31EDFF;
    cursor:pointer;
    font-size: 13px;
    border-radius: 22px;
    margin-top: 20px;
    font-weight: 400; 
    color: #FFFFFFFF; /* white */
    border: none; 
}
.div2_rightside_JobAndPlacementPage1_div2{
    width: 100%;
    height: 50%;
    background: transparent; 
    border-radius: 6px; 
    display: flex;
    padding-left: 20px;
    position: relative;
    top: -40px;
}
.div2_rightside_JobAndPlacementPage1_cartoondiv{
    width:40%;
    height: 100%;
    
}
.div2_rightside_JobAndPlacementPage1_getstartedbtndiv{
    width: 60%;
    height: 100%;
}
.div2_rightside_JobAndPlacementPage1_getstarted_h4{
    font-size: 1.25rem; 
    font-family: lexend;
    color: #6D31EDFF;
    padding-top: 50px;
    padding-right: 20px;
}
.div1_rightside_getStartedBtn{
    padding: 7px 15px;
    background-color:   #62CD14FF;
    cursor:pointer;
    font-size: 13px;
    border-radius: 22px;
    margin-top: 20px;
    font-weight: 400; 
    color: #FFFFFFFF; /* white */
    border: none;
}

#skilladdingsection{
    display: none;
}