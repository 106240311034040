.main-div-jobuserpro{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;   
    justify-content: center;
}
.jobuserpro-div1{
    width: 90%;
    height: auto;  
    background: #FFFFFFFF; /* white */
    border-radius: 8px; /* border-xl */
    border-width: 1px; 
    border-color: #F3F4F6FF; /* neutral-200 */
    border-style: solid;
    margin-top: 20px;
    z-index: -1;    
    padding-bottom: 20px;
}
.jobuserpro-div1-prodiv{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    position: relative;
    top: -30px;
    padding-left: 20px;
}
.jobuserpro-div1-username{
    font-family: Manrope; /* Body */
    font-size: 20px; 
    font-weight: 700; 
    color: #6D31EDFF; 
    text-align: center;
    text-align: left;
    margin-top: 7px;
}
.jobuserpro-div1-designation{
    font-family: Manrope; /* Body */
    font-size: 16px; 
    font-weight: 400; 
    color: #171A1FFF; 
    /* text-align: center; */
    text-align: left;
}
.jobuserpro-div1-about{
    font-family: Manrope; /* Body */
    font-size: 16px; 
    font-weight: 400; 
    color: #9095A1FF;
    width: 100%;
    text-align: center;
    margin-top: 15px;
    text-align: left;
}
.jobuserpro-div1-skilldiv{
    display: flex;
    justify-content: left;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-top: 10px;
}
.jobuserpro-div1-skill{
    font-family: Manrope; /* Body */
    font-size: 14px; 
    font-weight: 400; 
    opacity: 1;
    background-color: #F3F4F6FF;
    color: #9095A1FF;
    text-align: center;
    padding: 1px;
    margin: 1px;
    border-radius: 2px;
}
.jobuserpro-div1-h1{
    font-family: Manrope; /* Body */
    font-size: 16px; 
    line-height: 22px; 
    font-weight: 600; 
    color: #171A1FFF; 
    padding-left: 20px;
    padding-top: 15px;
    
}
.jobuserpro-div1-websitelinkdiv{
    font-family: Manrope; /* Body */
    font-size: 16px; 
    font-weight: 600; 
    color: #9095A1FF;
    background-color: #F3F4F6FF; /* neutral-200 */
    width: 96.5%;
    height: auto;
    padding-left: 15px;
    padding-top: 2px;
    padding-bottom: 2px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}
.jobuserpro-div1-editbtn{
    font-family: Manrope; 
    width: 96.5%;
    padding: 4px 4px;
    font-size: 16px; 
    font-weight: 400; 
    color: #FFFFFFFF; /* white */
    background: #6D31EDFF; /* primary-500 */
    opacity: 1; 
    border: none; 
    border-radius: 4px; 
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.jobuserpro-div2{
    width: 90%;
    height: auto;  
    background: #FFFFFFFF; /* white */
    border-radius: 8px; /* border-xl */
    border-width: 1px; 
    border-color: #F3F4F6FF; /* neutral-200 */
    border-style: solid;
    margin-top: 20px;
    z-index: -1;
    padding-bottom: 20px;
}
.jobuserpro-div2-analyticsdiv{
    width: 70%;
    height: auto;
    margin-left: 50px;
    margin-top: 20px;
    display: flex;

}
.jobuserpro-div2-analyticsdiv1{
    width: 160px;
    height: 95px;
    background: #FFFFFFFF; /* white */
    border-radius: 6px; /* border-l */
    border-width: 1px; 
    border-color: #F3F4F6FF; /* neutral-200 */
    border-style: solid; 
    margin: 0px 5px;
}
.jobuserpro-div3{
    width: 90%;
    height: auto;  
    background: #FFFFFFFF; /* white */
    border-radius: 8px; /* border-xl */
    border-width: 1px; 
    border-color: #F3F4F6FF; /* neutral-200 */
    border-style: solid;
    margin-top: 20px;
    z-index: -1;
    padding-bottom: 20px;
}

.main-div-jobuserpro-h2{
    font-family: Manrope; 
    font-size: 30px; 
    font-weight: 700; 
    color: #6D31EDFF;
}

.jobuserpro-div4{
    width: 90%;
    height: auto;  
    background: #FFFFFFFF; /* white */
    margin-top: 20px;
    z-index: -1;
    padding-bottom: 20px;
}
.jobuserpro-div4-h1{
    font-family: Manrope; /* Body */
    font-size: 30px; 
    font-weight: 700; 
    color: #6D31EDFF;
    padding-top: 20px;
}
.jobuserpro-div4-carddiv{
    width: 100%;
    height: auto;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.jobuserpro-div4-card1 {
    width: 18%;
    height: 100px;
    background: #FFFFFFFF; /* white */
    border-radius: 8px; /* border-xl */
    border-width: 1px; 
    border-color: #F3F4F6FF; /* neutral-200 */
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
}
.jobuserpro-div4-card1-namediv{
    width: auto;
    height: 35px;
    margin-left: 8px;
}
.jobuserpro-div4-card1-name{
    font-family: Manrope; /* Body */
    font-size: 14px; 
    font-weight: 900; 
    color: #171A1FFF; 
}
.jobuserpro-div4-card1-position{
    font-family: Manrope; /* Body */
    font-size: 14px; 
    font-weight: 400; 
    color: #6F7787FF; 
    padding-bottom:0px ;
}
.active{
    background-color: #F5F1FEFF;
    border-color: #6D31EDFF;
    
}
