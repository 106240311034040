@media only screen and (max-width:1351px) {
    .div1_leftside_JobAndPlacementPage1{
        width: 35%;
        height: 100%;
    }
   
}
@media only screen and (max-width:1187px) {
    .div1_leftside_JobAndPlacementPage1{
        width: 45%;
        height: 100%;
    }
}
@media only screen and (max-width:1060px) {
    .div1_JobAndPlacementPage1{
        height: 320px;
    }
    .div1_leftside_name{
        font-size: 15px; 
    }
    
    .div1_leftside_enternamediv{
        width: 60%;
        height: 32px;
    }
    
    .div1_leftside_img1{
        width: 30px;
        height: 30px;
        border-radius: 18px;
    }
    
    .div1_leftside_input1{
        background-color: #FFFFFFFF;
        width: 170px;
        height: 32px;
    }
    
    .div1_leftside_JobAndPlacementPage1_h1{
        font-weight: bolder;
        font-size: 22px;
        color: #323743FF;
        padding-left: 120px;
        padding-top: 10px;
        font-family: lexend;
    }
    .div1_leftside_JobAndPlacementPage1_h2{
        font-size: 10px; 
    }
    
    .div1-leftside_JobAndPlacementPage1_dropCVdiv{
        width: 270px; 
        height: 130px; 
    }
    
    .JobAndPlacementPage1_dropCVdiv_div1{
        width: 100%;
        height: 20px;
    }
    
    .JobAndPlacementPage1_dropCVheading{
        font-size: 11px; 
    }
    
    .JobAndPlacementPage1_CVselectingarea{
        width: 100%;
        height: 40px;
    }
    
    .JobAndPlacementPage1_buttonDiv{
        width: 100%;
        height: 20px;
    }
    
    .JobAndPlacementPage1_clearBtn{
        width: 65px; 
        height: 20px; 
        font-size: 10px; 
    }
    
    .JobAndPlacementPage1_uploadBtn{
        width: 65px; 
        height: 20px; 
        font-size: 10px; 
    }
    
    .div1_middleDiv_JobAndPlacementPage1{
        margin-top: 20px;
        width: 20%;
        height: 80%;
        padding: 10px;
    }
    
    .div1_rightside_h1{
        font-size: 15px;  
    }
    .div1_rightside_h2{
        color: #6D31EDFF;
        font-size: 30px;
        font-weight: 700;
        padding-top: 5px;
        font-family: lexend;
    }
    
    .div1_rightside_getOfferBtn{
        width: 80px;
        height: 27px;        
        font-size: 10px;
    }

    /* ****************************************Div2 Responsive************************************** */
    
    
    .div2_leftside_JobAndPlacementPage1_h1{
        font-size: 14px; 
        font-family: lexend;
        color: #6D31EDFF;
        padding-top: 10px;
        padding-left: 45px;
    }
    .div2_leftside_JobAndPlacementPage1_h2{
        font-size: 10px; 
        font-family: lexend;
        color: #171A1FFF;
        padding-top: 10px;
        padding-left: 45px;
    }
    .div2_leftside_JobAndPlacementPage1_skilldiv{
        width:100%;
        height: auto;
        margin-top: 10px;
        margin-left: 45px;
        display: flex;
    }
    
    .div2_leftside_JobAndPlacementPage1_skill{
        width: 60px; 
        height: 20px; 
        font-size: 7px; 
    }
    .div2_leftside_JobAndPlacementPage1_AddBtn{
        width: 45px; 
        height: 20px; 
        font-size: 10px;        
    }
    
    .div2_leftside_JobAndPlacementPage1_descdiv{
        width: 100%;
        height: auto;
    }
    
   
    .div2_rightside_JobAndPlacementPage1_h1{
        font-size: 10px; 
    }
    .div2_rightside_JobAndPlacementPage1_h2{
        font-size: 7px; 
    }
    .div1_rightside_viewFeedbackBtn{
        padding: 5px 15px;
        font-size: 10px;
    }
    .div2_rightside_JobAndPlacementPage1_cartoondiv{
        width:40%;
        height: 80%;       
    }
    .div2_rightside_JobAndPlacementPage1_getstartedbtndiv{
        width: 60%;
        height: 100%;
    }
    .div2_rightside_JobAndPlacementPage1_getstarted_h4{
        font-size: 0.9rem; 
        font-family: lexend;
        color: #6D31EDFF;
        padding-top: 50px;
        padding-right: 20px;
    }
    .div1_rightside_getStartedBtn{
        padding: 5px 15px;
        font-size: 10px;
    }

}
@media only screen and (max-width:850px) {
    .div1_JobAndPlacementPage1{
        height: auto;
        flex-wrap: wrap;
    }
    .div1_leftside_JobAndPlacementPage1{
        width: 50%;
        height: auto;
    }

    .div1_leftside_name{
        padding-left: 30px;
    }
    
    .div1_leftside_enternamediv{
        margin-left: 30px;
    }
    
    .div1_leftside_JobAndPlacementPage1_h1{
        padding-left: 60px;
    }
    .div1_leftside_JobAndPlacementPage1_h2{
        padding-left: 63px;
    }
    
    .div1-leftside_JobAndPlacementPage1_dropCVdiv{
        margin-left: 63px;
    }
    .div1_middleDiv_JobAndPlacementPage1{
        width: 35%;
        height: 300px;
    }
    
    .div1_rightside_JobAndPlacementPage1{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0px;
    }

    .div1_rightside_h1{
        font-size: 20px;  
    }
    .div1_rightside_h2{
        color: #6D31EDFF;
        font-size: 50px;
        font-weight: 700;
        padding-top: 5px;
        font-family: lexend;
    }
    
    .div1_rightside_getOfferBtn{
        width: 80px;
        height: 27px;        
        font-size: 10px;

    }

    /* ****************************************Div2 Responsive************************************** */
    

    

    .div2_JobAndPlacementPage1{
        flex-direction: column;
    }
    .div2_leftside_JobAndPlacementPage1{
        width: 100%;
        height: 100%;
    }
    
    .div2_leftside_JobAndPlacementPage1_descdiv{
        width: 100%;
        height: auto;
    }
    
    .div2_rightside_JobAndPlacementPage1{
        width: 100%;
        height: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .div2_rightside_JobAndPlacementPage1_div1{
        width: 95%;
        height: 350px;
        background: #FFFFFFFF; 
        border-radius: 6px; 
        box-shadow: 0px 4px 7px #1f212510, 0px 0px 4px #1d212757;
        margin-top: 13px;
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .div2_rightside_JobAndPlacementPage1_h1{
        font-size: 14px; 
    }
    .div2_rightside_JobAndPlacementPage1_h2{
        font-size: 10px; 
    }
    .div1_rightside_viewFeedbackBtn{
        padding: 5px 15px;
        font-size: 10px;
    }
    .div2_rightside_JobAndPlacementPage1_cartoondiv{
        width:40%;
        height: 80%;       
    }
    .div2_rightside_JobAndPlacementPage1_getstartedbtndiv{
        width: 60%;
        height: 100%;
    }
    .div2_rightside_JobAndPlacementPage1_getstarted_h4{
        font-size: 0.9rem; 
        padding-top: 50px;
        padding-right: 20px;
    }
    .div1_rightside_getStartedBtn{
        padding: 7px 15px;
        font-size: 13px;
    }
    .div2_rightside_JobAndPlacementPage1_cartoondiv{
        width:30%;
        height: 80%;       
    }
    .div2_rightside_JobAndPlacementPage1_getstartedbtndiv{
        width: 70%;
        height: 100%;
    }
    .div2_rightside_JobAndPlacementPage1_getstarted_h4{
        font-size: 1.5rem; 
        font-family: lexend;
    }

    
}
@media only screen and (max-width:530px){
    .div1_JobAndPlacementPage1{
        flex-direction: column;
        justify-content: center;
    }
    .div1_leftside_JobAndPlacementPage1{
        width: 100%;
        height: 100%;
    }
    .div1_middleDiv_JobAndPlacementPage1{
        width: 50%;
        height: 100%;
    }
}
@media only screen and (min-width:2500px){
    .div1_middleDiv_JobAndPlacementPage1{
        width: 10%;
        height: 100%;
    }
}