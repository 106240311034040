.App {
  text-align: center;
}

.dropZoneImages img {
  width: 88px;
}

.resourceCheckboxes svg {
  position: relative;
  top: 6px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mainCollapseContainer .chakra-collapse {
  overflow: unset !important;

}

.box-shadow {
  box-shadow: 0px 0px 7px 0px;
}

.box-border {
  border : 1px solid rgb(131, 131, 131)
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader {
  padding: 0;
  margin: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.loader-demo-box {
  border-radius: 0.25rem !important;
}

.loader-demo-box {
  width: 100%;
  height: 200px;
}

.jumping-dots-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.jumping-dots-loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: rgba(241, 83, 110, 0.8);
  margin: 35px 5px;
}

.jumping-dots-loader span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}

.jumping-dots-loader span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}

.jumping-dots-loader span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce {

  0%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

.top-contains {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin: 50px 0px;
}

.otp-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  /* overflow: hidden; */
  width: 600px;
  max-width: 100%;
  min-height: 379px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.otp-inputs {
  display: flex;
  justify-content: center;
}

.otpclassinput {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  margin: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.otpbutton {
  border-radius: 20px;
  border: 1px solid #6a65d5;
  background-color: #6a65d5;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.invalid-otp-box {
  border: 2px solid red;
}

.img-account-profile {
  height: 13rem;
}

.rounded-circle {
  border-radius: 50% !important;
}

.card {
  box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
}

.card .card-header {
  font-weight: 500;
}

.card-header:first-child {
  border-radius: 0.35rem 0.35rem 0 0;
}

.card-header {
  padding: 1rem 1.35rem;
  margin-bottom: 0;
  background-color: rgba(33, 40, 50, 0.03);
  border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}

.form-control,
.dataTable-input {
  display: block;
  width: 100%;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c5ccd6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-borders .nav-link.active {
  color: #0061f2;
  border-bottom-color: #0061f2;
}

.nav-borders .nav-link {
  color: #69707a;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}

.maindiv {
  height: 300px;
  /* background: #5e61ff47; */
  background: #5e61ff29;
}

.logodata {
  width: 100%;
  display: flex;
  justify-content: center;
}

.shimmerLoadingCourse {
  margin-top: 80px;
  gap: 30px;
}

.shimmerLoadingCourse .shimmer-card {
  margin-top: 0px !important;
  /* gap: 10px */
}

/* super admin css  */

.form-02-main {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

._lk_de {
  background-repeat: no-repeat;
  background-size: cover;
  /* padding:40px 0px; */
  position: relative;
}

.form-03-main {
  width: 500px;
  display: block;
  margin: 120px auto;
  padding: 25px 50px 25px;
  background: rgb(232 242 251 / 60%);
  border-radius: 6px;
  z-index: 9;
}

.superadminlogo {
  display: block;
  margin: 20px auto;
  width: 100px;
  height: 100px;
}

.super-form-group {
  padding: 15px 0px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.super-form-group p {
  margin: 0px;
}

.form-control {
  min-height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 15px;
  border-radius: 20px;
  border: 1px solid#2b3990;
}

.checkbox {
  display: flex;
  justify-content: space-around;
}

._btn_04 {
  display: inline-block;
  width: 100%;
  padding: 12px 0px;
  background: #2b3990;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  color: #fff;
}

/* ._btn_04 button{
  font-size:15px;
  color:#fff;
} */

._social_04 {
  display: block;
  width: 100%;
  padding: 15px 0px;
  text-align: center;
}

._social_04 ol li {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  transition: 0.2s;
  background: #fff;
  border-radius: 50px;
  border: 1px solid#2b3990;
  margin: 0 0 0 10px;
  transition: 0.2s;
}

._social_04 ol li:nth-child(1):hover {
  background: #3b5998;
  border: 1px solid#3b5998;
  color: #fff;
  transition: 0.2s;
}

._social_04 ol li:nth-child(2):hover {
  background: #00aced;
  border: 1px solid#00aced;
  color: #fff;
  transition: 0.2s;
}

._social_04 ol li:nth-child(3):hover {
  background: #c32f10;
  border: 1px solid#c32f10;
  color: #fff;
  transition: 0.2s;
}

._social_04 ol li:nth-child(4):hover {
  background: #e1306c;
  border: 1px solid#E1306C;
  color: #fff;
  transition: 0.2s;
}

._social_04 ol li:nth-child(5):hover {
  background: #0177b5;
  border: 1px solid#0177b5;
  color: #fff;
  transition: 0.2s;
}

._social_04 ol li:hover,
._social_04 ol li:hover i {
  color: #fff;
}

._social_04 ol li i:nth-child(1):hover {
  color: #fff;
}

._social_04 ol li i {
  font-size: 15px;
  color: #2b3990;
}

.nm_lk {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .form-03-main {
    width: 100%;
  }
}

/* super admin css  */

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.header h1 {
  font-size: 24px;
}

.main-content {
  display: flex;
}

.sidebar {
  width: 250px;
  background-color: #0051ff80;
  color: #fff;
  padding: 20px;
}

.sidebar h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style: none;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
  display: block;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}

.content {
  flex: 1;
  padding: 20px;
}

.content h1 {
  font-size: 28px;
  margin-bottom: 10px;
}

.content p {
  font-size: 16px;
}

.app.sidebar-open .sidebar {
  transform: translateX(0);
}

.sidebar {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  /* ... Other styles for closed sidebar */
}

.sidebar.open {
  transform: translateX(0);

  /* ... Other styles for open sidebar */
}

/* ... */

/* Add responsive styles for smaller screens */
@media (max-width: 768px) {
  .app.sidebar-open .sidebar {
    transform: translateX(0);
  }

  .sidebar {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
}