.menuContainer:hover {
    width: 200px;
}

.menuContainer:hover .menuTitle {
    display: inline;

}

.menuContainer:hover .menuListItem {
    width: 200px !important;
    text-align: left;
    /* transition: all 0.3s ease-in-out; */

}
.menuListItem {
    cursor: pointer;
}

.menuContainer:hover .logoContainer {
    width: 100%;

}

.menuLinkContainer {
    display: flex;
}
.menuLink {
    cursor: pointer;
}