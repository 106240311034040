.modalContainer {}

.drawerContainer {
    max-width: 100% !important;
    width: 440px !important;
}


.linkWidget {
    border-radius: 10px;
    background-color: #e9e9e9;
    text-align: left;
    align-items: center;
    display: flex;
    /* flex-direction: column; */
    gap: 5px;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
}


.contentContainer {
    display: flex;
    align-items: center;
    gap:10px;
}

.buttonContainer button {
    font-size: 12px;
    height: 25px;

}

.buttonContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}

.sectionContainer {
    margin-bottom: 5px;
}

.sectionContainer h6 {
    margin-bottom: 10px;
}

.contentContainer p {
    width: 250px;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}