.logodata {
  width: 100%;
  display: flex;
  justify-content: center;
}

.maindiv {
  height: 300px;
  /* background: #5e61ff47; */
  background: #5e61ff29;
}

.userTypeList {
  justify-content: center;
  width: 100%;
}